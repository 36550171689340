import React from 'react'

function ProjectsComponent() {
  return (
    <div>
         <p className='font-bold text-lg mt-5'>Projects</p>
        <hr className="h-1 bg-black" />
        <div className="mt-5">
            <div className="flex justify-between">
                <p><b>Trunk Scale Software | Angular, Electron, Node.js, MongoDB </b></p>
                <p><b>  Jan 2021 - Mar 2021 </b></p>
            </div>

                <li>Built a Trunk Scale software that includes vehicle number detection, auto weight calculation, image capturing, and bill generation for a weighbridge company that manages <b>50+</b> vehicles daily</li>
                <li>Worked as a <b>Full Stack developer</b> in a team of 4 and also scheduled weekly calls with the client which <b>reduced</b> the completion time by <b>2 weeks</b> </li>
            
            <div className="flex justify-between mt-5">
            <p><b>Samyak 2020 | Angular, Node.js, TypeScript, MongoDB, AWS </b></p>
                <p><b> Oct 2020 - Jan 2021 </b></p>
            </div>
            
                <li>An Event Management System that includes a user management system, a <b>dynamic event schedule management system</b>, and a payment portal</li>
                <li>Handled <b>20k</b> users in 3 days with low latency and processed over <b>10k</b> payments with <b>99.9%</b> success rate</li>
                <li>An email delivery system using SendGrid API notifies students about their payment status and event schedules, which improves communication between  participants and organizers </li>
            
        </div>
    </div>
  )
}

export default ProjectsComponent