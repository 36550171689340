import React from 'react'

function AchievementsComponent() {
  return (
    <div>
        <p className='font-bold text-lg mt-5'>Projects</p>
        <hr className="h-1 bg-black" />
        <div className="mt-3">
        <li><b>Finalist</b> in India’s largest Hackathon, Smart India Hackathon 2020 </li>
        <li><b>Semi-finalist</b> in a national hackathon conducted by K L Hack League among 20+ colleges</li>
        <li>Secured <b>3rd position</b> among 50 teams  in a National level hackathon (HashInclude 2k19)</li>
        </div>
        
    </div>
  )
}

export default AchievementsComponent