import React, { useEffect, useState } from 'react'


const NavbarComponent = () => {
  let [count,setCount] = useState(0);
  useEffect(()=>{
    fetch('https://3vpgxsin4m.execute-api.us-east-1.amazonaws.com/prod/get-visitors/update')
  },[])
   useEffect(()=>{
    
     fetch('https://3vpgxsin4m.execute-api.us-east-1.amazonaws.com/prod/get-visitors')
                      .then(response=>response.json())
                      .then(body=>setCount(body["body"]["Count"]))
                      .then(()=>console.log({count}))
  })
  // async function getVisitorsCount(){
    
  // }
  return (
    <div>   
            <div className="flex justify-center">
                
                <div className='flex-col text-center '>
                    <h1 className='font-bold text-3xl'>Bhaskar Praveen Naidu</h1>
                    <p>Bloomington, IN 47401 | +1  (737) 206-4341| <a href='mailto:bhaskarpraveen14@gmail.com'><u>bhaskarpraveen14@gmail.com</u></a></p>
                    <p><a href='https://www.github.com/bhaskarpraveen' target="_blank" rel="noreferrer"><u>github.com/bhaskarpraveen</u></a> | <a href='https://www.linkedin.com/in/bhaskar-praveen' target="_blank" rel="noreferrer"><u>linkedin.com/in/bhaskar-praveen</u></a></p>
                </div>
                <p>Visitor count: {count}</p>
            </div>
            
           
    </div>

  )
}

export default NavbarComponent