import React from 'react'

function WorkexComponent() {
  return (
    <div>
        <p className='font-bold text-lg mt-5'>Work Experience</p>
        <hr className="h-1 bg-black" />
        <div className="mt-5">
            <div className="flex justify-between">
                <p><b>Motorola Solutions, USA | Software Engineer</b></p>
                <p><b> July 2023 - Dec 2023 </b></p>
            </div>

                <li>Developed an Infrastructure as Code (IaC) script to <b>automate</b> PagerDuty configurations across diverse environments</li>
                <li>Created an Elasticsearch worker  to detect and notify when audit logs ceased for minutes </li>
                <li>Deployed multiple services across diverse production environments, acquiring skills in <b>Azure deployments</b></li>
            
            <div className="flex justify-between mt-5">
            <p><b>Motorola Solutions, India | Site Reliability Engineer </b></p>
                <p><b> Mar 2021 - Mar 2022 </b></p>
            </div>
            
                <li>Implemented an IAM and security module in the existing OneUi application which <b>enhanced</b> the <b>security</b> of the application by restricting teams to specific permissions</li>
                <li>Handled millions of records from different data sources like Prometheus, Elasticsearch, and Graphite to <b>detect anomalies</b> by identifying various thresholds using machine-learning models</li>
                <li>Used Python scripting to make changes in schemas for existing records in the <b>production databases</b> in various environments</li>
                <li>Mentored a team of 5 students from Charusat University on <b>MEAN stack</b> as a part of Industry Training, who were recruited as interns after the training program</li>
                <li>Facilitated, assisted, and scheduled interviews for <b>50+</b> candidates, also a member of the interview panel</li>
            
            <div className="flex justify-between mt-5">
            <p><b>Magnanime Global Solutions, Remote | Full-Stack  Developer</b></p>
                <p><b>Apr 2020 - Jun 2020</b></p>
            </div>
           
                <li>Coordinated a team of interns in developing a <b>Content Management System</b> for the official website of Magnanime, which helped them post job openings whenever required</li>
                <li>Designed and Developed an Employee Management System to manage records, leaves, and timesheets of <b>100+</b> employees in the company, which saved them a lot of time and effort </li>
                <li>Designed an architecture for the entire project and deployed it using <b>Docker, Nginx,</b> and <b>AWS</b></li>
           
        </div>
        
    </div>
  )
}

export default WorkexComponent