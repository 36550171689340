import React from 'react'

function EducationComponent() {
  return (
    <div>
         <div className="flex-col">
                <p className='font-bold text-lg'>Education</p>
                <hr className="h-1 bg-black" />
                <div className="flex justify-between mt-3">
                    <div className="flex-col ">
                        <p><b>Indiana University Bloomington,</b>Bloomington, IN </p>
                        <p><b>Luddy School of Informatics, Computing, and Engineering</b></p>
                        <p>Master of Science in Computer Science</p>
                        <p><b>Relevant Coursework:</b> Applied Algorithms, Advanced Database Concepts, Software Engineering</p>
                        <p><b>Certifications:</b> AWS Certified Solutions Architect - Associate</p>
                    </div>
                    <div className="flex-col">
                        <b>Aug 2022 – May 2024</b>
                        <p>GPA: 3.5/4.0</p>
                    </div>
                </div>
                <div className="flex justify-between mt-5">
                    <div className="flex-col ">
                        <p><b>K L University, </b> Guntur, India </p>
                        <p>Bachelors in Computer Science and Engineering (Hons.)</p>
                        <p>Master of Science in Computer Science</p>
                        <p><b>Achievements: </b>Teaching Assistant, Google Developers Club Mentor</p>
                        <p><b>Relevant Coursework:</b>Data Structures, Web Engineering,  Database Management Systems, Cloud System Infrastructure</p>
                        <p><b>Certifications:</b>Cloud Practitioner (AWS Certified), Multi-Cloud Network Associate (Aviatrix Certified Engineer)</p>
                    </div>
                    <div className="flex-col">
                        <b>Jun 2018 – Mar 2022</b>
                        <p>GPA: 9.0/10.0</p>
                    </div>
                </div>
                
            </div>
    </div>
  )
}

export default EducationComponent