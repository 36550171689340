import React from 'react'

function SkillsComponent() {
  return (
    <div>
        <p className='font-bold text-lg mt-5'>Technical Skills</p>
        <hr className="h-1 bg-black" />
        <div className="flex justify-normal mt-5">
            <div className="flex-col">
            <p> <b>Programming Languages</b></p>
            <p> <b>Frameworks</b></p>
            <p> <b>Database</b></p>
            <p> <b>Tools</b></p>
            <p> <b>Cloud</b></p>
            </div>
            <div className="flex-col ml-10">
                <p>Python, Java, JavaScript/TypeScript, C</p>
                <p>ExpressJS, Django, Angular, React, React Native</p>
                <p>MySQL, PostgreSQL, MongoDB, GraphQL</p>
                <p>Git, Docker, Selenium</p>
                <p>AWS CloudFormation, AWS S3, AWS EC2, AWS RDS, Azure </p>
            </div>
        </div>
    </div>
  )
}

export default SkillsComponent