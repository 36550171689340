import React,{useEffect} from 'react';
import NavbarComponent from './components/Navbar'
import EducationComponent from './components/Education'
import SkillsComponent from './components/Skills'
import WorkexComponent from './components/Workex'
import ProjectsComponent from './components/Projects'
import AchievementsComponent from './components/Achievements'

function App() {

  return (
    <div className="App">
     <NavbarComponent/>
  <div className="flex-col ml-14 mr-14">
    <EducationComponent/>
    <SkillsComponent/>
    <WorkexComponent/>
    <ProjectsComponent/>
    <AchievementsComponent/>
  </div>
    </div>
  );
}

export default App;
